<template>
  <b-modal
    id="ImgModal"
    v-model="ImgModal"
    centered
    title="修改原因"
    size="lg"
    title-class="font-18"
    hide-footer
    style="min-height: 300px"
    class="d-flex justify-content-center align-items-center"
  >
    <div class="flexList check-distri mb-3">
    <label class="mb-0" style="width: 9%">原因：</label>
    <div class="col-sm-10 p-0 flexList">
      <input type="text" v-model="sfzjhxgsm" placeholder="请输入修改原因" class="form-control w-50 h30" />
      <button style="margin-left: 10px" type="button" class="btn btn-info h30 w-md mr-3"
              @click="submit">
        确定修改
      </button>
    </div>
  </div>
  </b-modal>
</template>

<script>
import {updateUserInfoSfzjh} from "@/api/userInfo_user";

export default {
  data() {
    return {
      ImgModal: false,
      sfzjh: "",
      sfzjhxgsm: "",
      sfzjlxm: "",
      sfzjlxmc: "",
    };
  },
  methods: {
    submit(){
      let form = {
        sfzjhxgsm: this.sfzjhxgsm,
        sfzjlxm: this.sfzjlxm,
        sfzjlxmc: this.sfzjlxmc,
      }
      updateUserInfoSfzjh(this.sfzjh, form).then(res=>{
        if (res.status){
          this.$notify.success("修改成功");
          this.ImgModal = false;
        }
      })
    },
    show(sfzjh, sfzjlxm, sfzjlxmc) {
      console.log(sfzjh, sfzjlxm, sfzjlxmc)
      this.sfzjh = sfzjh
      this.sfzjlxm = sfzjlxm
      this.sfzjlxmc = sfzjlxmc
      this.ImgModal = true;
    },
    hide() {
      this.ImgModal = false;
    },
  },
};
</script>

<style></style>
