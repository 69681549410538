<template>
  <div>
    <div v-if="typeof bound == 'object'" class="flexList">
      <div v-for="(item, i) in dataList" :key="i">
        <label for="item.value" v-if="!readOnly" class="radio-label">
          <input
            type="radio"
            :name="bound[0]"
            :id="item.value"
            :value="item.value"
            :checked="form[bound[0]] == item.value"
            @change="Change($event, bound)"
          />{{ item.name }}
        </label>
        <div v-if="readOnly && form[bound[0]] == item.value">
          {{ item.name }}
        </div>
      </div>
    </div>

    <div v-if="typeof bound == 'string'" class="flexList">
      <div v-for="(item, i) in dataList" :key="i">
        <label for="item.value" v-if="!readOnly" class="radio-label">
          <input
            type="radio"
            :name="bound"
            :id="item.value"
            :value="item.value"
            :checked="form[bound] == item.value"
            @change="Change($event, bound)"
          />{{ item.name }}
        </label>
        <div v-if="readOnly && form[bound] == item.value">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  model: {
    prop: "form",
    event: "update",
  },
  props: {
    dict: Array,
    bound: null,
    form: Object,
    readOnly: Boolean,
  },
  data() {
    return {
      dataList: [],
    };
  },
  methods: {
    Change(e, v) {
      if (typeof v == "string") {
        let value = $("input[type='radio']:checked").val();
        this.form[v] = value;
      } else {
        let bound = ["value", "name"];
        let value = $("input[type='radio']:checked").val();
        let data = this.dataList.filter((item) => {
          return item.value == value;
        })[0];
        for (const index in this.bound) {
          if (index < 2) {
            this.form[v[index]] = data[bound[index]];
          } else {
            let [k1, v1] = this.bound[index].split(":");
            this.form[k1] = data[v1];
          }
        }
        this.$emit("change", data);
      }
      this.$emit("update", this.form);
    },
    updateData() {
      let checked = this.dataList.find((item) => {
        if (typeof this.bound == "string") {
          return this.form[this.bound] == item.value;
        } else {
          return this.form[this.bound[0]] == item.value;
        }
      });
      // if (!checked && this.dataList.length > 0) {
      //   checked = this.dataList[0];
      // }
      if (checked) {
        if (typeof this.bound == "string") {
          this.form[this.bound] = checked.value;
        } else {
          let bound = ["value", "name"];
          for (const index in this.bound) {
            this.form[this.bound[index]] = checked[bound[index]];
          }
        }
      }
    },
  },
  watch: {
    dict: {
      deep: true,
      immediate: true,
      handler(e) {
        if (e) {
          this.dataList = e;
          this.updateData();
        }
      },
    },
  },
  mounted() {
    // this.updateData();
  },
};
</script>

<style>
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
.radio-label {
  margin-bottom: unset;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.radio-label input {
  margin-right: 10px;
}
.radioText {
  padding: 0 12px;
}
</style>
