<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
  .msgValInput[readOnly] {
    width: unset !important;
  }

  .el-form-item {
    margin-bottom: unset;
  }

  .msgValInput {
    background-color: transparent;
    border: unset;
    padding-left: 0;
    width: 60% !important;
  }

  .msgVal {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .msgtextarea .msgVal {
    display: block;
  }

  /deep/.el-input__inner {
    background-color: #fff9eb;
    width: 100%;
    height: 35px;
  }

  /deep/ .el-textarea__inner {
    background-color: #fff9eb;
  }

  /deep/.el-form-item__content {
    line-height: inherit;
  }

  .el-form-item--small .el-form-item__error {
    margin-top: 2px;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }

  /deep/.el-form-item__error {
    position: absolute !important;
    top: 20%;
    left: 60%;
    text-align: center;
    width: 120px;
    height: 21px;
    border-radius: 2px;
    font-size: 12px;
    line-height: 21px;
    z-index: 99;
  }

  .el-radio {
    margin-bottom: 0;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import PersonHeader from "@/views/pages/admin/user/examinee/person-header.vue";
  import YzRadio from "@/components/form/yzRadio.vue";

  // import { provinceAndCityData, CodeToText } from "element-china-area-data";//省市不带'全部'的二级联动
  // import { provinceAndCityDataPlus, CodeToText } from "element-china-area-data";//省市带'全部'的二级联动
  // import { regionDataPlus, CodeToText } from "element-china-area-data";//省市区带'全部'的三级联动
  import {
    regionData
  } from "element-china-area-data"; //省市区不带'全部'的三级联动
  import {
    handleChange,
    regionChange,
    changeOption,
    boundDefaultData,
    getDictionary,
  } from "@/libs/common_user";

  import {
    userInfo,
    updateUserInfo
  } from "@/api/userInfo_user.js";
  import YzModal from "@/components/form/yzModal.vue";
  import {
    handleUserInfo
  } from "@/api/userInfo_user";
  import handleId from "./comp/handleId";
  /**
   * PersonData
   */
  export default {
    components: {
      Layout,
      PageHeader,
      handleId,
      PersonHeader,
      YzRadio,
      YzModal,
    },
    computed: {
      newFormData() {
        return JSON.parse(JSON.stringify(this.formDataPersonal));
      },
    },
    data() {
      const checkIdcard = (rule, value, cb) => {
        const regIdcard =
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        if (regIdcard.test(value)) {
          return cb();
        }
        cb(new Error("请输入正确的身份证号"));
      };
      const checkMobile = (rule, value, cb) => {
        const regMobile =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g;
        if (regMobile.test(value)) {
          return cb();
        }
        cb(new Error("请输入正确的手机号"));
      };
      const checkEmail = (rule, value, cb) => {
        const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
        if (regEmail.test(value)) {
          return cb();
        }
        cb(new Error("请输入正确的邮箱"));
      };
      return {
        title: "个人资料完善",
        items: [{
            text: "资料完善",
          },
          {
            text: "基本信息",
            active: true,
          },
        ],
        page: "basic",
        isFirst: true,
        options: regionData, //省市区数据
        selectoptions: [], //选中的地区
        formDataPersonal: {
          xm: "",
          sfzjh: "",
          xbm: "",
          xb: "",
          csrq: "",
          mzmc: "",
          mzm: "",
          sfzjlxm: "",
          sfzjlxmc: "",
          zzmmmc: "",
          zzmmm: "",
          csdm: "",
          csdmc: "",
          jg: "",
          ssdm: "", //所在省市码
          ssmc: "", //省市名称
          djsdm: "",
          djsmc: "",
          qxdm: "",
          qxmc: "",
          hjszdm: "",
          hjszdmc: "",
          hyztm: "",
          hyztmc: "",
          gjdqm: "",
          gjdqmc: "",
          gatqwm: "", //港澳台侨外码
          gatqwmc: "",
          jkzkm: "", //健康状况码
          jkzkmc: "",
          xyzjm: "",
          xyzjmc: "",
          zgxlm: "", //最高学历码
          zgxlmc: "",
          zgxlxxfs: "", //最高学历学习方式
          zgxwm: "",
          zgxwmc: "",
          zgxwxxfs: "",
          zhbyxxmc: "", //最后毕业学校名称
          zhbyzy: "",
          zhbyxxlb: "",
          zhbyxxdq: "",
          zhbyxxdqm: "", //最后毕业学校地区码
          zcmc: "", //职称名称
          zcm: "",
          hdzcsj: "",
          cjgzsj: "",
          xgzdw: "",
          szdwzw: "",
          ywxscfjl: "",
          xscfjl: "",
          ywdjzjcfjl: "",
          djzjcfjl: "",
          ywbkcgzhctjl: "",
          kcgzhctjl: "",
          grjl: "",
          jtzz: "",
          yzbm: "",
          lxdh: "",
          yddh: "",
          dzxx: "",
          zpzt: 0,
          xlzt: 0
        },
        /* rules: {
          xm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          sfzjh: [
            {
              required: true,
              message: "必填",
              trigger: "blur",
            },
            {
              validator: checkIdcard,
              trigger: "change",
            },
          ],

          xbm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          csrq: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          mzm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          zzmmm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          csdm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          jg: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          hjszdm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          hyztm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          gjdxm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          // gatqwm: [
          //   {
          //     required: true,
          //     message: "必填",
          //     trigger: "change",
          //   },
          // ], //港澳台侨外
          jkzkm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ], //健康状况
          xyzjm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ], //信仰宗教
          zgxlm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ], //最高学历码
          zgxlxxfs: "", //最高学历学习方式
          zgxwm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          zgxwxxfs: "",
          zhbyxxmc: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ], //最后毕业学校名称
          zhbyzy: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          zhbyxxlb: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          zhbyxxdqm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ], //最后毕业学校地区
          zcm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          hdzcsj: "",
          cjgzsj: "", //参加工作时间
          xgzdw: "", //现工作单位
          szdwzw: "", //所在单位职务
          ywxscfjl: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          xscfjl: "",
          ywdjzjcfjl: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          djzjcfjl: "",
          ywbkcgzhctjl: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          kcgzhctjl: "",
          grjl: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          jtzz: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          yzbm: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
          ],
          lxdh: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
            {
              validator: checkMobile,
              trigger: "change",
            },
          ],
          dzxx: [
            {
              required: true,
              message: "必填",
              trigger: "change",
            },
            {
              // 通过validator 进行自定义校验规则
              validator: checkEmail,
              trigger: "change",
            },
          ],
        }, */
        sjzd: {
          dict_xb: [],
        },
        handleForm: {},
      };
    },
    methods: {
      checkRegister: function() {
        // this.$refs["formDataPersonal"].validate((valid) => {
        //   if (valid) {
        //     updateUserInfo(this.formDataPersonal).then((res) => {
        //       if (res.status) {
        //         this.$router.push("./personalImage");
        //         this.$notify({
        //           title: "成功",
        //           message: "信息保存成功",
        //           type: "success",
        //         });
        //         return false;
        //       }
        //       this.$notify({
        //         title: "失败",
        //         message: "res.message",
        //         type: "error",
        //       });
        //     });
        //   }
        // });
        this.$router.push("./personalImage");
      },
      changeOption(event, form, kv) {
        changeOption(event, form, kv);
        this.$forceUpdate();
      },
      handleChange(event, form, name, code) {
        handleChange(event, form, name, code);
      },
      model() {
        let sjzd = this.sjzd;
        let fromData = this.formDataPersonal;
        let data = {
          dict_mz: "mzm",
          dict_zzmm: "zzmmm",
          dict_hyzt: "hyztm",
          dict_gjdq: "gjdqm,zhbyxxdqm",
          dict_gatqw: "gatqwm",
          dict_jkzk: "jkzkm",
          dict_xyzj: "xyzjm",
          dict_xl: "zgxlm",
          dict_xw: "zgxwm",
          dict_xxfs: "xxfsm",
          dict_zc: "zcm",
          dict_sfzjlx: "sfzjlxm"
        };
        boundDefaultData(sjzd, fromData, data);

        this.$forceUpdate();
      },
      //单独校验
      oneCheck(data) {
        return new Promise((resolve, reject) => {
          for (const key in data) {
            this.$refs["formDataPersonal"].validateField(key, (valid) => {
              if (valid) {
                reject(false);
              }
              resolve(true);
            });
          }
        });
      },
      //单字段修改
      oneCahnge(data) {
        updateUserInfo(this.$route.query.sfzjh, data).then((res) => {
          if (res.status) {
            return;
          }
          this.$notify({
            title: "失败",
            message: "res.message",
            type: "error",
          });
        });
      },
      // oneCahnge(data) {
      //   this.oneCheck(data).then((res) => {
      //     if (res) {
      //       updateUserInfo(this.$route.query.sfzjh, data).then((res) => {
      //         if (res.status) {
      //           return;
      //         }
      //         this.$notify({
      //           title: "失败",
      //           message: "res.message",
      //           type: "error",
      //         });
      //       });
      //     }
      //   });
      // },
      handleInfo() {
        console.log(this.$refs["yzmodal"].show());
      },
      handle() {
        handleUserInfo(this.formDataPersonal.sfzjhEnc, this.handleForm).then(
          (res) => {
            if (res.status) {
              this.$notify({
                title: "成功",
                message: "审核成功",
                type: "success",
              });
              this.$refs["personHeader"].init();
            }
          }
        );
      },
    },
    mounted() {
      var _this = this;
      userInfo(this.$route.query.sfzjh).then((res) => {
        if (res.status) {
          if (res.data) {
            _this.formDataPersonal = res.data;
          }
          getDictionary(
            "dict_mz",
            "dict_zzmm",
            "dict_hyzt",
            "dict_gjdq",
            "dict_gatqw",
            "dict_jkzk",
            "dict_xyzj",
            "dict_xl",
            "dict_xxfs",
            "dict_xw",
            "dict_zc",
            "dict_xb",
            "dict_sfzjlx"
          ).then((res) => {
            this.sjzd = res;
            this.sjzd.dict_xb = this.sjzd.dict_xb.filter((item) => {
              return item.value == "1" || item.value == "2";
            });
            this.model();
            regionChange(_this.formDataPersonal, ["csdm", "hjszdm"]);
          });
        }
      });
    },
    watch: {
      newFormData: {
        deep: true,
        handler(newVal, oldVal) {
          if (!this.isFirst) {
            let changeData = {};
            for (const key in oldVal) {
              if (newVal[key] != oldVal[key]) {
                changeData[key] = newVal[key];
              }
            }
            this.oneCahnge(changeData);
          } else {
            this.isFirst = false;
          }
        },
      },
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <PersonHeader :page="page" ref="personHeader" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-2">基本资料</span>
            </h4>
            <div class="outerCotent" >
              <el-form :model="formDataPersonal" ref="formDataPersonal" class="mb-2">
                <input type="hidden" class="msgValInput" value="" name="sid" />
                <div class="msgContent flexList fs-xs">
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName">姓名</div>
                    <div class="msgVal" style="display: unset">
                      <input class="msgValInput w-40" readOnly disabled v-model="formDataPersonal.xm" /><span
                        class="grey-time">（无法修改，如需修改请联系主管部门）</span>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">身份证件类型</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="">
                        <el-select v-model="formDataPersonal.sfzjlxm_" placeholder="请选择身份证件类型" class="msgValInput"
                          value-key="name" @change="$refs.handleId.show(formDataPersonal.sfzjhEnc, $event.value, $event.name);changeOption($event, formDataPersonal, [
                              'sfzjlxmc',
                              'sfzjlxm',
                            ])">
                          <el-option v-for="(v, i) of sjzd.dict_sfzjlx" :label="v.name" :value="v" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">身份证号</div>
                    <div class="msgVal" style="display: unset">
                      <input class="msgValInput w-40" readOnly disabled v-model="formDataPersonal.sfzjh" /><span
                        class="grey-time">（无法修改，如需修改请联系主管部门）</span>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">性别</div>
                    <div class="msgVal">
                      <el-form-item prop="xb">
                        <yz-radio :dict="sjzd.dict_xb" :bound="['xbm', 'xb']" v-model="formDataPersonal"></yz-radio>
                        <!-- <el-radio-group v-model="formDataPersonal.xbm">
                          <el-radio label="1">男</el-radio>
                          <el-radio label="2">女</el-radio>
                        </el-radio-group> -->
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">出生日期</div>
                    <div class="msgVal">
                      <el-form-item prop="csrq">
                        <el-date-picker v-model="formDataPersonal.csrq" type="date" placeholder="选择日期"
                          value-format="yyyy-MM-dd" class="msgValInput">
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">民族</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="mzm">
                        <el-select v-model="formDataPersonal.mzm_" placeholder="请选择民族" class="msgValInput"
                          value-key="name" @change="
                            changeOption($event, formDataPersonal, [
                              'mzmc',
                              'mzm',
                            ])
                          ">
                          <el-option v-for="(v, i) of sjzd.dict_mz" :label="v.name" :value="v" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">政治面貌</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="zzmmm">
                        <el-select v-model="formDataPersonal.zzmmm_" placeholder="请选择政治面貌" class="msgValInput"
                          value-key="name" @change="
                            changeOption($event, formDataPersonal, [
                              'zzmmmc',
                              'zzmmm',
                            ])
                          ">
                          <el-option v-for="(v, i) in sjzd.dict_zzmm" :label="v.name" :value="v" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">籍贯</div>
                    <div class="msgVal">
                      <el-form-item prop="jg">
                        <el-input placeholder="请输入籍贯" v-model="formDataPersonal.jg" class="msgValInput"></el-input>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">出生地</div>
                    <div class="msgVal">
                      <el-form-item prop="csdm">
                        <el-cascader :options="options" v-model="formDataPersonal.csdm_" class="msgValInput" @change="
                            handleChange(
                              $event,
                              formDataPersonal,
                              {csdmc: 'Ssq'},
                              {csdm: 'q'}
                            )
                          "></el-cascader>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">户籍所在地</div>
                    <div class="msgVal">
                      <el-form-item prop="hjszdm">
                        <el-cascader :options="options" v-model="formDataPersonal.hjszdm_" class="msgValInput" @change="
                            handleChange(
                              $event,
                              formDataPersonal,
                              {
                                hjszdmc: 'Ssq',
                                ssmc: 'S',
                                djsmc: 's',
                                qxmc: 'q',
                              },
                              {hjszdm: 'q', ssdm: 'S', djsdm: 's', qxdm: 'q'}
                            )
                          "></el-cascader>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">婚姻状态</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="hyztm">
                        <el-select v-model="formDataPersonal.hyztm_" placeholder="请选择婚姻状态" class="msgValInput"
                          value-key="name" @change="
                            changeOption($event, formDataPersonal, [
                              'hyztmc',
                              'hyztm',
                            ])
                          ">
                          <el-option v-for="(v, i) in sjzd.dict_hyzt" :label="v.name" :value="v" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">所在国家地区</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="gjdqm">
                        <el-select v-model="formDataPersonal.gjdqm_" placeholder="请选择国家地区" class="msgValInput"
                          value-key="name" @change="
                            changeOption($event, formDataPersonal, [
                              'gjdqmc',
                              'gjdqm',
                            ])
                          ">
                          <el-option v-for="(v, i) in sjzd.dict_gjdq" :label="v.name" :value="v" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">港澳台侨</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="gatqwm">
                        <el-select v-model="formDataPersonal.gatqwm_" placeholder="请选择是否港澳台侨" class="msgValInput"
                          value-key="name" @change="
                            changeOption($event, formDataPersonal, [
                              'gatqwmc',
                              'gatqwm',
                            ])
                          ">
                          <el-option v-for="(v, i) in sjzd.dict_gatqw" :label="v.name" :value="v" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">健康状态</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="jkzkm">
                        <el-select v-model="formDataPersonal.jkzkm_" placeholder="请选择健康状态" class="msgValInput"
                          value-key="name" @change="
                            changeOption($event, formDataPersonal, [
                              'jkzkmc',
                              'jkzkm',
                            ])
                          ">
                          <el-option v-for="(v, i) in sjzd.dict_jkzk" :label="v.name" :value="v" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">宗教信仰</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="xyzjm">
                        <el-select v-model="formDataPersonal.xyzjm_" placeholder="请选择宗教信仰" class="msgValInput"
                          value-key="name" @change="
                            changeOption($event, formDataPersonal, [
                              'xyzjmc',
                              'xyzjm',
                            ])
                          ">
                          <el-option v-for="(v, i) in sjzd.dict_xyzj" :label="v.name" :value="v" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">最高学历</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="zgxlm">
                        <el-select v-model="formDataPersonal.zgxlm_" placeholder="请选择最高学历" class="msgValInput"
                          value-key="name" @change="
                            changeOption($event, formDataPersonal, [
                              'zgxlmc',
                              'zgxlm',
                            ])
                          ">
                          <el-option v-for="(v, i) in sjzd.dict_xl" :label="v.name" :value="v" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">最高学位</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="zgxwm">
                        <el-select v-model="formDataPersonal.zgxwm_" placeholder="请选择最高学位" class="msgValInput"
                          value-key="name" @change="
                            changeOption($event, formDataPersonal, [
                              'zgxwmc',
                              'zgxwm',
                            ])
                          ">
                          <el-option v-for="(v, i) in sjzd.dict_xw" :label="v.name" :value="v" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">最后毕业学校</div>
                    <div class="msgVal">
                      <el-form-item prop="zhbyxxmc">
                        <el-input v-model="formDataPersonal.zhbyxxmc" placeholder="请输入最后毕业学校" class="msgValInput">
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">最后毕业专业</div>
                    <div class="msgVal">
                      <el-form-item prop="zhbyzy">
                        <el-input v-model="formDataPersonal.zhbyzy" placeholder="请输入最后毕业专业" class="msgValInput">
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">最后毕业学校类别</div>
                    <div class="msgVal">
                      <el-form-item prop="zhbyxxlb">
                        <el-radio-group v-model="formDataPersonal.zhbyxxlb">
                          <el-radio label="境内">境内</el-radio>
                          <el-radio label="境外">境外</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">最后毕业学校国家地区</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="zhbyxxdqm">
                        <el-select v-model="formDataPersonal.zhbyxxdqm_" placeholder="请选择最后毕业学校国家地区" class="msgValInput"
                          value-key="name" @change="
                            changeOption($event, formDataPersonal, [
                              'zhbyxxdq',
                              'zhbyxxdqm',
                            ])
                          ">
                          <el-option v-for="(v, i) in sjzd.dict_gjdq" :label="v.name" :value="v" :key="i">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">当前职称</div>
                    <div class="msgVal selectShow">
                      <el-form-item prop="zcm">
                        <el-select v-model="formDataPersonal.zcm_" placeholder="请选择当前职称" class="msgValInput"
                          value-key="name" @change="
                            changeOption($event, formDataPersonal, [
                              'zcmc',
                              'zcm',
                            ])
                          ">
                          <el-option v-for="(v, i) in sjzd.dict_zc" :label="v.name" :value="v" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">获得职称时间</div>
                    <div class="msgVal">
                      <el-form-item prop="hdzcsj" class="msgValInput">
                        <el-date-picker v-model="formDataPersonal.hdzcsj" type="date" placeholder="选择日期"
                          value-format="yyyy-MM-dd">
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">现工作单位</div>
                    <div class="msgVal">
                      <el-form-item prop="xgzdw">
                        <el-input v-model="formDataPersonal.xgzdw" placeholder="现工作单位" class="msgValInput"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">所在单位职务</div>
                    <div class="msgVal">
                      <el-form-item prop="szdwzw">
                        <el-input v-model="formDataPersonal.szdwzw" placeholder="所在单位职务" class="msgValInput"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel msgtextarea">
                    <div class="msgName flexList justify-content-center">
                      有无刑事处罚记录
                    </div>
                    <div class="msgVal">
                      <el-form-item prop="ywxscfjl">
                        <el-radio-group v-model="formDataPersonal.ywxscfjl">
                          <el-radio :label="1">有</el-radio>
                          <el-radio :label="0">无</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item prop="xscfjl">
                        <el-input type="textarea" placeholder="请输入刑事处罚记录" rows="3" maxlength="200" class="msgValInput"
                          v-model="formDataPersonal.xscfjl"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel msgtextarea">
                    <div class="msgName flexList justify-content-center">
                      有无党纪政纪处分记录
                    </div>
                    <div class="msgVal">
                      <el-form-item prop="ywdjzjcfjl">
                        <el-radio-group v-model="formDataPersonal.ywdjzjcfjl">
                          <el-radio :label="1">有</el-radio>
                          <el-radio :label="0">无</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item prop="djzjcfjl">
                        <el-input type="textarea" placeholder="请输入党纪政纪处分记录，不超过200字" rows="3" maxlength="200"
                          class="msgValInput" v-model="formDataPersonal.djzjcfjl"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel msgtextarea">
                    <div class="msgName flexList justify-content-center">
                      有无被开除公职或辞退记录
                    </div>
                    <div class="msgVal">
                      <el-form-item prop="ywbkcgzhctjl">
                        <el-radio-group v-model="formDataPersonal.ywbkcgzhctjl">
                          <el-radio :label="1">有</el-radio>
                          <el-radio :label="0">无</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item prop="kcgzhctjl">
                        <el-input type="textarea" placeholder="请输入开除公职或辞退记录，不超过200字" rows="3" maxlength="200"
                          class="msgValInput" v-model="formDataPersonal.kcgzhctjl"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel msgtextarea">
                    <div class="msgName flexList justify-content-center">
                      个人简介
                    </div>
                    <div class="msgVal pt-3">
                      <el-form-item prop="grjl">
                        <el-input type="textarea" placeholder="请输入个人简介，不超过500字" rows="5" maxlength="500"
                          class="msgValInput" v-model="formDataPersonal.grjl"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">家庭住址</div>
                    <div class="msgVal">
                      <el-form-item prop="jtzz">
                        <el-input v-model="formDataPersonal.jtzz" placeholder="请输入家庭住址" class="msgValInput"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">邮政编码</div>
                    <div class="msgVal">
                      <el-form-item prop="yzbm">
                        <el-input v-model="formDataPersonal.yzbm" class="msgValInput"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">联系电话</div>
                    <div class="msgVal" style="diaplay: unset">
                      <el-form-item prop="lxdh">
                        <div class="flexList">
                          <el-input  v-model="formDataPersonal.lxdh"
                            class="msgValInput w-40 mr-3"></el-input>

                         <!-- <button type="button" class="btn btn-info h30 ">
                            更改
                          </button> -->
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">电子信箱</div>
                    <div class="msgVal">
                      <el-form-item prop="dzxx">
                        <el-input v-model="formDataPersonal.dzxx" class="msgValInput"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">照片状态</div>
                    <div class="msgVal">
                      <el-form-item prop="zhbyxxlb">
                        <el-radio-group v-model="formDataPersonal.zpzt">
                          <el-radio :label="0">未通过</el-radio>
                          <el-radio :label="1">已通过</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">学历状态</div>
                    <div class="msgVal">
                      <el-form-item prop="zhbyxxlb">
                        <el-radio-group v-model="formDataPersonal.xlzt">
                          <el-radio :label="0">未填写</el-radio>
                          <el-radio :label="1">已填写</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- <div class="flexList w-100 msgLabel" style="height: 50px">
                    <div class="msgName"></div>
                    <div class="msgVal" style="
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                      ">
                      <button type="button" class="btn btn-info h35 sub-btn" @click="handleInfo">
                        审定基本信息
                      </button>
                      <a :href="
                          '/admin/personalImage?sfzjh=' +
                          this.$route.query.sfzjh
                        ">
                        <button style="margin-left: 10px" type="button" class="btn btn-info h35 sub-btn"
                          @click="checkRegister">
                          审定完成,进入下一步
                        </button>
                      </a>
                    </div>
                  </div> -->
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <YzModal ref="yzmodal" title="基本信息审定" :bound="['shzt', 'shbz']" v-model="handleForm" @change="handle"></YzModal>
    <handleId ref="handleId"></handleId>
  </Layout>
</template>
